import QueryString from 'query-string'
import React, { useEffect, useState } from 'react'

import GeneralSearch from 'src/components/GeneralSearch'
import FilterOffers from './FilterOffers/_index'

import useDomReady from 'src/hooks/window/useDomReady'

import { SectionWrapper } from './style'

const PublicOfferings = () => {
  const type = 'disponiveis'
  const [ searchText, setSearchText ] = useState('')
  const [ queryString, setQueryString ] = useState<{ [key: string]: string | undefined }>({})
  const domReady = useDomReady()

  useEffect(() => {
    if (domReady) {
      const parsedQuery = QueryString.parse(window.location.href)
      const formattedQuery: { [key: string]: string | undefined } = {}
      Object.keys(parsedQuery).forEach((key: string) => {
        const value = parsedQuery[key]
        formattedQuery[key] = Array.isArray(value) ? value[0] : value || undefined
      })
      setQueryString(formattedQuery)
      setSearchText(queryString.q || '')
    }
  }, [ searchText, domReady ])

  return (
    <SectionWrapper id='ofertas-publicas' className='bg-grayscale--100 py-5'>
      <div className='container py-xl-5'>
        <div className='row justify-content-center justify-content-md-between align-items-center'>
          <div className='col-12 px-md-0'>
            <h2 className='fs-24 lh-28 fs-lg-40 lh-lg-47 fw-600 text-grayscale--500 text-md-center mb-0'>
              Ofertas Públicas disponíveis
            </h2>
            <GeneralSearch placeholderText='Qual oferta pública você procura?' setInputText={setSearchText} styles='col-12 px-0 px-md-3' />
            <FilterOffers searchText={searchText} type={type} />
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default PublicOfferings
