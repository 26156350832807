import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import { rounded } from 'src/styles/rounded'

import Filter from './assets/images/action-filter-gray.svg'

type ButtonProps = {
  modalOpen: string;
}

export const BoxFilterContainer = styled.div<ButtonProps>`
  display: ${(props: ButtonProps) => `${props.modalOpen}`};
  background-color: rgba(0,0,0,.4);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 8; 
`

export const ButtonFilter = styled.button`
  background: ${grayscale['500']} url(${Filter}) no-repeat;
  border-radius: ${rounded['6']};
  background-position: center right 10px;
  color: ${white};
  margin-top: 8px;
  margin-right: 8px;
  width: 87px;
  height: 32px;
  padding: 0 16px 1px 16px;
  z-index: 9;
  position: relative;
  outline: none !important;
  text-transform: capitalize;
  border: 1px solid  ${grayscale['200']};

  @media ${device.tablet} {
    margin-right: 24px;
  }

  &.white {
    background: ${white};
    color: ${grayscale['500']};
    border: 1px solid  ${grayscale['200']};
    padding: 0 10px 1px 10px;
    width: 97px;

    &.active {
      background: ${grayscale['500']};
      color: ${white};
    }
  }
`

export const BoxFilterWhite = styled.ul<ButtonProps>`
  display: ${(props: ButtonProps) => `${props.modalOpen}`};
  list-style: none;
  margin-bottom: 0;
  background: ${white};
  border-radius: ${rounded['5']};
  width: 316px;
  min-height: 462px;
  padding: 31px 15px 16px 15px;
  top: 0;
  left: -3px;
  position: absolute;   
  z-index: 9;
  outline: none !important;
  
  @media ${device.tablet} {
    top: -25px;
    width: 377px;
    min-height: 496px;
    padding-left: 16px;
    padding-right: 16px;
    left: 16px;   
  }
  
  li {
    display: block;
    width: 100%;
    min-height: 30px;
    margin-bottom: 15px;
  }
`

export const ButtonsFilterWhite = styled.button`
  background: ${white};
  height: 31px;
  font-size: 12px;
  line-height: 15px;
  border-radius: ${rounded['6']};
  border: 1px solid ${grayscale['200']};
  outline: none !important;
  margin-right: 8px;
  padding: 0 10px;

  @media ${device.tablet} {
    padding: 0 20px;
  }

  &.active {
    background: ${grayscale['500']};
    color: ${white};
  }
`

export const ButtonClear = styled.button`
  width: 89px;
  height: 48px;
  background: ${white};
  color: ${orange.extra};
  border-radius: 8px;
  border: 1px solid ${orange.extra};
  outline: none !important;
`

export const ButtonFilterOrange = styled.button`
  width: 89px;
  height: 48px;
  background: ${orange.extra};
  color: ${white};
  border-radius: 8px;
  border: 1px solid ${orange.extra};
  outline: none !important;
`

export const Slider = styled.div`

  .input-range {
    padding-top: 50px;
    width: 94%;
    margin: 0 auto;

    &__label {
      display: none;     
    }     

    &__slider {
      background: ${white};
      width: 24px;
      height: 24px;
      border: 2px solid ${orange.extra};
      margin-top: -14px;
    }

    &__track {
      background: ${grayscale['100']};

      &--active {
        background: ${orange.extra};
      }
    }
  }
`
