import { format } from 'date-fns'
import React, { useState } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import ScrollTo from 'src/components/ScrollTo'
import Icon from 'src/components/UI/MarkdownIcon'
import { convertFloatToCurrencyString, formatDate } from 'src/shared/helpers'

import PublicOfferings from 'src/assets/data/ofertas-publicas/ofertas.json'

import { BoxOffers, LimitPage } from './style'
import { OffersProps, SearchProps } from './types'

const PublicOfferingsCards = ({ searchText, type, typeOrdering, typeOffers, minMaxValueFilter }: SearchProps) => {
  format(new Date(2014, 1, 11), 'MM/dd/yyyy')

  const encerradas = PublicOfferings.encerradas.map((item: OffersProps) => item)
  const disponiveis = PublicOfferings.disponiveis.map((item: OffersProps) => item)
  const totalOffers = type === 'disponiveis' ? disponiveis : encerradas
  const message = 'Nenhuma oferta encontrada!'
  let filterTotalOffers = totalOffers

  const limit = 4
  const [ offset, setOffset ] = useState(limit)
  const [ sendDatalayerEvent ] = useDataLayer()

  const MoreOffers = () => {
    setOffset(offset + limit)
  }

  if (typeOrdering === 'Investimento mínimo') {
    filterTotalOffers.sort((a: OffersProps, b: OffersProps) => (a.investimentoMinimo > b.investimentoMinimo)
    ? 1 : ((b.investimentoMinimo > a.investimentoMinimo) ? -1 : 0))
  }

  if (typeOrdering === 'Período de reserva') {
    filterTotalOffers.sort((a: OffersProps, b: OffersProps) => {
      if (a.dataLimiteReserva === null) return 1
      if (b.dataLimiteReserva === null) return -1
      return a.dataLimiteReserva > b.dataLimiteReserva ? 1 : (b.dataLimiteReserva > a.dataLimiteReserva ? -1 : 0)
    })
  }

  if (typeOffers.length > 0) {
    !typeOrdering && filterTotalOffers.sort((a: OffersProps, b: OffersProps) => (a.categoria > b.categoria)
    ? 1 : ((b.categoria > a.categoria) ? -1 : 0))

    filterTotalOffers = totalOffers.filter((totalOffer: OffersProps) => {
      return typeOffers.includes(totalOffer.categoria)
    })
  }

  if (minMaxValueFilter) {
    const minMaxValue = filterTotalOffers.filter((item: OffersProps) => (
      item.investimentoMinimo >= minMaxValueFilter.min) && item.investimentoMinimo <= minMaxValueFilter.max,
    )
    filterTotalOffers = minMaxValue
  }

  if (filterTotalOffers.length === 0 && !searchText) {
    return <p className='text-center fs-14 lh-17 text-grayscale--500 pt-4'>{message}</p>
  }

  const searchItems = (query: string) => {
    return totalOffers.filter((item: OffersProps) => {
      if (query !== undefined) {
        return item.titulo.toLowerCase().indexOf(query.toLowerCase()) > -1
      }
    })
  }

  return (
    <>
      {searchItems(searchText).map((item: OffersProps, index: number) => {
        if (searchText !== '') {
          return (
            <BoxOffers key={index} className='col-12 col-md-6 bg-white py-3 px-0'>
              <div className='col px-0 pt-2 d-flex flex-column align-items-center justify-content-between'>
                <div className='col-12 fs-16 lh-20 fw-600 text-grascale--500 py-3 pt-md-2 pl-4 pr-3 height-title'>
                  {item.titulo}
                </div>
                <div className='col-10 border-top align-items-center pt-3 pb-1 px-0'>
                  <div className='mr-2 ml-n1 bg-grayscale--100 icon pl-0 float-left'>
                    <Icon color='grayscale--500' width='14' height='14' icon='products/pj/faturamento' directory='v2' />
                  </div>
                  <span className='d-block fs-12 lh-16 text-grayscale--400'>Tipo de oferta</span>
                  <span className='fs-12 lh-16 text-grayscale--500 fw-600 mt-1'>{item.categoria}</span>
                </div>
                <div className='col-10 align-items-center ml-3 ml-lg-2 ml-xl-0 pt-2 pb-1 px-0'>
                  <div className='mr-2 ml-n1 bg-grayscale--100 icon pl-0 float-left'>
                    <Icon color='grayscale--500' width='14' height='14' icon='products/investimentos/fundos' directory='v2' />
                  </div>
                  <span className='d-block fs-12 lh-16 text-grayscale--400'>Investimento Mínimo</span>
                  <span className='fs-12 lh-16 text-grayscale--500 fw-600 d-block mt-1'>
                    {convertFloatToCurrencyString(item.investimentoMinimo)}
                  </span>
                </div>
                <div className='col-10 align-items-center ml-3 ml-lg-2 ml-xl-0 pt-2 pb-4 px-0'>
                  <div className='mr-2 ml-n1 bg-grayscale--100 icon pl-0 float-left'>
                    <Icon color='grayscale--500' width='14' height='14' icon='action/calendar' directory='v2' />
                  </div>
                  <span className='d-block fs-12 lh-16 text-grayscale--400'>Data limite da reserva</span>
                  <span className='fs-12 lh-16 text-grayscale--500 fw-600 d-block mt-1'>
                    {item.dataLimiteReserva !== null ? formatDate(item.dataLimiteReserva) : '-'}
                  </span>
                </div>
                <div className='col-12 d-flex justify-content-end align-items-center pr-3'>
                  <a
                    href={`/pra-voce/investimentos/ofertas-publicas/${item.slug}/`}
                    className='fs-12 lh-15 d-block fw-700'
                    title='Ver detalhes da Oferta'
                    data-home-ofertas-publicas='detalhes'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_02',
                        element_action: 'click button',
                        element_name: 'Detalhes da Oferta',
                        section_name: item.titulo,
                        redirect_url: `/pra-voce/investimentos/ofertas-publicas/${item.slug}/`,
                      })
                    }}
                  >
                    Detalhes da Oferta
                  </a>
                  <Icon width='27.12' height='24' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                </div>
              </div>
            </BoxOffers>
          )
        }
      })}

      { !searchText &&
        filterTotalOffers.slice(0, offset).map((item: OffersProps, index: number) => (
          <BoxOffers key={index} className='col-12 col-md-6 bg-white py-3 px-0'>
            <div className='col px-0 d-flex flex-column align-items-center justify-content-between'>
              <div className='col-12 fs-16 lh-20 fw-600 text-grascale--500 py-3 pt-md-2 pl-4 pr-3 height-title'>
                {item.titulo}
              </div>
              <div className='col-10 border-top align-items-center pt-3 pb-1 px-0'>
                <div className='mr-2 ml-n1 bg-grayscale--100 icon pl-0 float-left'>
                  <Icon color='grayscale--500' width='14' height='14' icon='products/pj/faturamento' directory='v2' />
                </div>
                <span className='d-block fs-12 lh-16 text-grayscale--400'>Tipo de oferta</span>
                <span className='fs-12 lh-16 text-grayscale--500 fw-600 mt-1'>{item.categoria}</span>
              </div>
              <div className='col-10 align-items-center pt-2 pb-1 px-0'>
                <div className='mr-2 ml-n1 bg-grayscale--100 icon pl-0 float-left'>
                  <Icon color='grayscale--500' width='14' height='14' icon='products/investimentos/fundos' directory='v2' />
                </div>
                <span className='d-block fs-12 lh-16 text-grayscale--400'>Investimento Mínimo</span>
                <span className='fs-12 lh-16 text-grayscale--500 fw-600 d-block mt-1'>
                  {convertFloatToCurrencyString(item.investimentoMinimo)}
                </span>
              </div>
              <div className='col-10 align-items-center pt-2 pb-4 px-0'>
                <div className='mr-2 ml-n1 bg-grayscale--100 icon pl-0 float-left'>
                  <Icon color='grayscale--500' width='14' height='14' icon='action/calendar' directory='v2' />
                </div>
                <span className='d-block fs-12 lh-16 text-grayscale--400'>Data limite da reserva</span>
                <span className='fs-12 lh-16 text-grayscale--500 fw-600 d-block mt-1'>
                  {item.dataLimiteReserva !== null ? formatDate(item.dataLimiteReserva) : '-'}
                </span>
              </div>
              <div className='col-12 d-flex justify-content-end align-items-center pr-3'>
                <a
                  href={`/pra-voce/investimentos/ofertas-publicas/${item.slug}/`}
                  className='fs-12 lh-15 d-block fw-700'
                  title='Ver detalhes da Oferta'
                  data-home-ofertas-publicas='detalhes'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_02',
                      element_action: 'click button',
                      element_name: 'Detalhes da Oferta',
                      section_name: item.titulo,
                      redirect_url: `/pra-voce/investimentos/ofertas-publicas/${item.slug}/`,
                    })
                  }}
                >
                  Detalhes da Oferta
                </a>
                <Icon width='27.12' height='24' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
              </div>
            </div>
          </BoxOffers>
        ))
      }

      { !searchText &&
        <LimitPage className='col-12 d-flex mt-lg-4'>
          {offset <= filterTotalOffers.length
            ? (
              <button onClick={MoreOffers}>
                <Icon color='grayscale--500' width='24' height='24' icon='navigation/chevron-down' directory='v2' />
              </button>
            )
            : (
              <ScrollTo
                to='#ofertas-publicas'
                styles='mx-auto'
                section='dobra_02'
                sectionName='Ofertas Públicas disponíveis'
                elementName='Clicou na seta'
              >
                <button onClick={MoreOffers}>
                  <Icon color='grayscale--500' width='24' height='24' icon='navigation/chevron-up' directory='v2' />
                </button>
              </ScrollTo>
            )
          }
        </LimitPage>
      }
    </>
  )
}

export default PublicOfferingsCards
